import percentageOfElementInViewPort from "./percentageOfElementInViewPort"

function determineLevelInView(
        lvlHeightRefArray, 
        strandHeightRefArray, 
        illHeightRefArray
    ){
        let levelInView = 1
        let refArray = null
        
        if (window.scrollY === window.scrollMaxY) return levelInView
        
        if (lvlHeightRefArray[0].current !== null) refArray = lvlHeightRefArray
        if (strandHeightRefArray[0].current !== null) refArray = strandHeightRefArray
        if (illHeightRefArray[0].current !== null) refArray = illHeightRefArray
        
        if (refArray === null) return levelInView
        
        const inViewArray = refArray.map(a => percentageOfElementInViewPort(a)).filter(Number)
        
        if (window.scrollY === 0) return inViewArray.length
        
        const inViewArrayMinus50 = inViewArray.map(a => Math.abs(a - 50))
        const sorted = inViewArrayMinus50.slice().sort((a,b) => a-b)
        const closestTo = sorted[0]
        const indexOfClosestTo50 = inViewArrayMinus50.indexOf(closestTo)
        levelInView = indexOfClosestTo50 + 1

        return levelInView
}

export default determineLevelInView