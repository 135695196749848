import ButtonContainer from "./ButtonContainer";
import ArrowLeft from "./ArrowLeft";

function LeftButton(props){
    const text = props.text;
    return (
        <ButtonContainer>
            <ArrowLeft />
            <span className="pl1 b">{text}</span>
        </ButtonContainer>
    );
}

export default LeftButton