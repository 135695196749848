import scrollToLevel from './scrollToLevel';

function mapClickEventToContentViewAction(
    nameValueArray,
    renderLayerNumber,
    levelInView,
    setRenderLayerNumber,
    setNumericalScaleHeight,
    refArray,
    lvlHeightRefArray,
    strandHeightRefArray,
    illHeightRefArray
) {

    let lastControllerClickTemp = {
        Layer: Number(nameValueArray[1]), 
        Level: Number(nameValueArray[3]), 
        Strand: Number(nameValueArray[5])
    }

    // from layer N to layer 1
    if (lastControllerClickTemp.Layer === 1){
        setNumericalScaleHeight(0)
        setRenderLayerNumber(1)
        window.scrollTo(0,0)
      return 
    }

    // from layer 1 to layer N
    if (renderLayerNumber === 1) {
        const toBottom = 99999999
    
        setTimeout(()=>{
            setRenderLayerNumber(lastControllerClickTemp.Layer)
            setNumericalScaleHeight(refArray[lastControllerClickTemp.Layer].current.clientHeight)
            window.scrollTo(0,toBottom)
        },0)
    }

    // from layer m to n excluding 1
    if (renderLayerNumber !== 1 && (renderLayerNumber !== lastControllerClickTemp.Layer)){
        
        setNumericalScaleHeight(1280)
        setRenderLayerNumber(lastControllerClickTemp.Layer)
        setTimeout(()=>{
            setNumericalScaleHeight(refArray[lastControllerClickTemp.Layer].current.clientHeight)
            let options = {  block: "center" }
            scrollToLevel(
                levelInView, 
                options,
                lvlHeightRefArray, 
                strandHeightRefArray, 
                illHeightRefArray)  
        },0)
             
        return
    }
}

export default mapClickEventToContentViewAction