function percentageOfElementInViewPort(element) {
    if (element.current  === null) return 'null'

    // Get the relevant measurements and positions
    const viewportHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const elementOffsetTop = element.current.offsetTop;
    const elementHeight = element.current.offsetHeight;

    // Calculate percentage of the element that's been seen
    const distance = scrollTop + viewportHeight - elementOffsetTop;
    const percentage = Math.round(
        distance / ((viewportHeight + elementHeight) / 100)
    );

    // Restrict the range to between 0 and 100
    const percentageInViewPort = Math.min(100, Math.max(0, percentage));

    return 100 - percentageInViewPort 

}

export default percentageOfElementInViewPort