import Cross from "./Cross";

function Modal(props) {
    const clickOk = props.clickOk;
    
    return (
            <div className='bw1 ba b--dark-purple br2 bg-white modal selectedBox shadow-4' onClick={e => e.stopPropagation()}>
                <div className='mt3 mb2 ml3'>
                    <div className="flex flex-row-reverse mr3 bb bw2 b--dark-purple">
                        <Cross closeModal={clickOk} />
                    </div>
                    <div 
                        className='ml2 dark-gray mb2' 
                        style={{overflow: 'auto', maxHeight: '80vh'}}>
                        <div className='mr2'>
                            {props.children}
                        </div>
                    </div>
                </div>           
            </div>
    );
}

export default Modal;