import StrandName from '../SharedContentView/StrandName'

function LevelOfStrands(props){

    const width = props.width
    const scrollY = props.scrollY
    const levelNumber = props.levelNumber
    const strandName = props.strandName
    const strandLabelWidth = props.strandLabelWidth
    const numberOfLevels = props.numberOfLevels

    let showStrandName
    levelNumber === numberOfLevels ? showStrandName = true : showStrandName = false
    
    return (
        <>
            <div 
                className={'flex flex-column bg-white ml2 mr2 bl br bw1 b--dark-purple bb bt br2 mb3'} 
                style={{width: `${width}px`, marginTop: '0vh'}}>
                {showStrandName && 
                    <StrandName 
                        scrollY={scrollY}
                        strandName={strandName} 
                        width={strandLabelWidth}/>}
                            {props.children}
            </div>
        </>
    )
}

export default LevelOfStrands