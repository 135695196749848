import NumericalScale from '../SharedContentView/NumericalScale/NumericalScale'
import ItemContainer from './ItemContainer'
import LevelOfStrandsItems from './LevelOfStrandItems'
import Levels from '../SharedContentView/Levels'
import LevelIndicator from '../SharedContentView/LevelIndicator'
import PurpleLevelDivider from '../SharedContentView/PurpleLevelDivider'
import FadeInOutMessage from '../FadeInOutMessage'

function LayerFour(props){
    const illHeightRefArray = props.illHeightRefArray
    const NAV_BAR_HEIGHT = props.NAV_BAR_HEIGHT
    const setItemHeightToNumericalScaleHeightRef = props.setItemHeightToNumericalScaleHeightRef
    const numericalScaleHeight = props.numericalScaleHeight
    const lpeRenderNumber = props.lpeRenderNumber
    const levelInView = props.levelInView

    const LayerThreeData = props.LayerThreeData
    const allItemHTML = props.LayerFourData
    const strandNames = LayerThreeData.strandNames
    const scrollY = props.scrollY

    const levelDividerMarginLeft = props.levelDividerMarginLeft
    const levelDividerMarginRight = props.levelDividerMarginRight

    const strandStatementMargin = props.strandStatementMargin
    const levelOfStrandsWidth = props.levelOfStrandsWidth
    const totalStrandViewWidth = props.totalStrandViewWidth
    const strandLabelWidth = props.strandLabelWidth

    const levelIndicatorWidth = props.levelIndicatorWidth

    const readingMessage = props.readingMessage
    const copyrightMessage = props.copyrightMessage

    const setShowLayerFourItemModal = props.setShowLayerFourItemModal
    const setLayerFourItemModalHTML = props.setLayerFourItemModalHTML

    let levels = []
    let levelStrandTemp = []
    let levelOfStrandsTemp = []
    let levelNumber
    let numberOfStatements

    const numberOfLevels = allItemHTML.length

    for (let i=allItemHTML.length-1; i>=0; i--){
        levelNumber = i+1
        for (let j=0; j<allItemHTML[i].length; j++){
        numberOfStatements = allItemHTML[i][j].length
            for (var k=0; k<allItemHTML[i][j].length; k++){
                levelStrandTemp.push(
                        <ItemContainer
                            setShowLayerFourItemModal={setShowLayerFourItemModal}
                            setLayerFourItemModalHTML={setLayerFourItemModalHTML}
                            statementNumber={k}
                            numberOfLevels={numberOfLevels}
                            levelNumber={levelNumber} 
                            strandName={strandNames[j]}
                            numberOfStatements={numberOfStatements}
                            margin={strandStatementMargin} 
                            itemHTML={allItemHTML[i][j][k]}/>
                    )
            }

            levelOfStrandsTemp.push(
                <LevelOfStrandsItems 
                    numberOfLevels={allItemHTML.length}
                    strandNumber={j}
                    scrollY={scrollY}
                    strandName={strandNames[j]}
                    strandLabelWidth={strandLabelWidth}
                    levelNumber={i+1} 
                    width={levelOfStrandsWidth}>
                        {levelStrandTemp}
                </LevelOfStrandsItems>)
            levelStrandTemp = []
        }
        if (numberOfLevels === levelNumber){
            levels.push(<div style={{height:'8vh'}}></div>)
        }
        levels.push(
            <PurpleLevelDivider 
                levelDividerMarginLeft={levelDividerMarginLeft}
                levelDividerMarginRight={levelDividerMarginRight}
                levelNumber={levelNumber}
                lpeRenderNumber={lpeRenderNumber}/>)
        
        levels.push(
            <Levels
                heightRef={illHeightRefArray[i]}>
                    {levelOfStrandsTemp}
            </Levels>)
        levelOfStrandsTemp = []
    }

    // Hacky, but will do for now until a revised schema for learning progressions is devised.
    if (lpeRenderNumber === 1)
        levels.push(<FadeInOutMessage message={readingMessage} width={totalStrandViewWidth}/>)
    
    levels.push(<FadeInOutMessage message={copyrightMessage} width={totalStrandViewWidth} />)

        return (
        <>
            <div className='flex justify-between bg-moon-gray'>
                <NumericalScale 
                    NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                    numericalScaleHeight={numericalScaleHeight}/>
                <div className='flex flex-column' style={{width: `${totalStrandViewWidth}px`}} ref={setItemHeightToNumericalScaleHeightRef}>
                        {levels}
                </div>
                <div>
                    <LevelIndicator 
                        lpeRenderNumber={lpeRenderNumber}
                        levelInView={levelInView}
                        levelIndicatorWidth={levelIndicatorWidth}/>
                </div>
            </div>
        </>
    )
}

export default LayerFour