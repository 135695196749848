import { useState } from 'react'
import logo from '../shared/logo.svg'
import NavigationController from '../NavigationController/NavigationController'

function NavigationBar(props){

  const copyrightMessage = props.copyrightMessage
  const NAV_BAR_HEIGHT = props.NAV_BAR_HEIGHT
  const levelInView = props.levelInView
  const renderNavigationController = props.renderNavigationController
  const InfoData = props.InfoData
  const renderLayerNumber = props.renderLayerNumber
  const NUMBER_OF_STRANDS = props.NUMBER_OF_STRANDS
  const NUMBER_OF_LEVELS = props.NUMBER_OF_LEVELS
  const L4_ELEMENT_WIDTH = props.L4_ELEMENT_WIDTH
  const L4_ELEMENT_HEIGHT = props.L4_ELEMENT_HEIGHT
  const L3_ELEMENT_WIDTH = props.L3_ELEMENT_WIDTH
  const L3_ELEMENT_HEIGHT = props.L3_ELEMENT_HEIGHT    
  const TOP_OFFSET_FACTOR = props.TOP_OFFSET_FACTOR
  const RIGHT_OFFSET_FACTOR = props.RIGHT_OFFSET_FACTOR
  const L2_LEVEL_WIDTH = props.L2_LEVEL_WIDTH
  const L2_LEVEL_HEIGHT = props.L2_LEVEL_HEIGHT
  const L1_WIDTH = props.L1_WIDTH
  const L1_HEIGHT = props.L1_HEIGHT
  const NAVIGATION_CONTROL_AREA_WIDTH=props.NAVIGATION_CONTROL_AREA_WIDTH
  const NAVIGATION_CONTROLLER_MARGIN_LEFT = props.NAVIGATION_CONTROLLER_MARGIN_LEFT
  const NAVIGATION_CONTROLLER_CONTAINER_HEIGHT = props.NAVIGATION_CONTROLLER_CONTAINER_HEIGHT
  const handleNavigationControllerClick = props.handleNavigationControllerClick
  const lpeRenderNumber = props.lpeRenderNumber

  const [showHideMenu, setShowHideMenu] = useState(false)

  function showMenu(e){
    e.preventDefault()
    setShowHideMenu(true)
  }

  function hideMenu(e){
    e.preventDefault()
    setShowHideMenu(false)
  }

  // Custom height for LP reading and maths
  // TODO: pull this out into an array like the other strand/level dependent dimensions and set with useContext
  const menuHeight = lpeRenderNumber === 1 ? '595px' : '650px'

  return (
    <>
    <nav 
      onMouseLeave={hideMenu} 
      className="flex justify-between items-center h3 b--dark-purple bb bw5 bg-white" 
      style={{zIndex:'20', position: 'fixed', width: '100vw', minHeight: `${NAV_BAR_HEIGHT}`, top: '0'}}>
        <div className="ml4 flex items-end">
          <a href="https://www.acer.org/au" target="_blank" rel='noreferrer'>
              <img src={logo} className='mt2' alt='ACER logo'/>
          </a>
          <div className="f3 dark-purple ml3 mb1">Learning Progression Explorer</div>
        </div>
      {!renderNavigationController &&
        <div className='mr2 mt2 show-menu' onClick={showMenu}>
          <div className='dark-purple mr2 pointer' style={{position: 'relative', display:'inline-block', fontSize:'40px', marginRight: '1.5vw'}}>&#9776;</div>
        </div>}
              {showHideMenu && 
                  <div style={{position: 'absolute', right:'21px', top: '8vh'}} className=''>
                    <div className='bg-white ba br1 bw1 b--dark-purple' style={{width: '360px', height: menuHeight}}>
                      <NavigationController
                        levelInView={levelInView}
                        InfoData={InfoData} 
                        renderLayerNumber={renderLayerNumber}
                        handleNavigationControllerClick={handleNavigationControllerClick}
                        NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                        NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                        L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
                        L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
                        L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH}
                        L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
                        TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}
                        RIGHT_OFFSET_FACTOR={RIGHT_OFFSET_FACTOR}
                        L2_LEVEL_WIDTH={L2_LEVEL_WIDTH}
                        L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
                        L1_WIDTH={L1_WIDTH}
                        L1_HEIGHT={L1_HEIGHT}
                        NAVIGATION_CONTROL_AREA_WIDTH={NAVIGATION_CONTROL_AREA_WIDTH}
                        NAVIGATION_CONTROLLER_MARGIN_LEFT={NAVIGATION_CONTROLLER_MARGIN_LEFT}
                        NAVIGATION_CONTROLLER_CONTAINER_HEIGHT={NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}/>
                    </div>
                </div>}
      </nav>
    </>
    )
  } 

  export default NavigationBar;