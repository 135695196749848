function TickMark(props){
    const isMajor = props.isMajor
    const width = props.width
    const height = props.height

    return (
        <div className='bb bw1 b--dark-purple bw1' style={{width:`${width}px`,height:`${height}px`}}></div>
    )
}

export default TickMark