function LevelIndicator(props){

    const levelIndicatorWidth = props.levelIndicatorWidth
    const levelInView = props.levelInView
    const lpeRenderNumber = props.lpeRenderNumber

    let levelOffset
    lpeRenderNumber === 1 ? levelOffset = 4 : levelOffset = 0

    return (
        <div 
            className='flex justify-center items-center' 
            style={{position: 'fixed', top: '50vh', right: '0vw', width: `${levelIndicatorWidth}px`}}>
                <div 
                    className='ba h3 flex items-center justify-center br1 bg-white bw1 b--dark-purple shadow-1' 
                    style={{width: `${0.7*levelIndicatorWidth}px`}}>
                    <div className='flex flex-column'>
                        <div className='flex justify-center f6'>
                            Level
                        </div>
                        <div>
                            <div className='flex justify-center f4 b mt1'>
                                {levelInView + levelOffset}
                            </div>
                        </div>
                    </div>
                </div>
        </div>)
}

export default LevelIndicator