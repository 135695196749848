import RequisiteSpacing from "./Spacings/RequisiteSpacing"
import LayerThreeSpacing from "./Spacings/LayerThreeSpacing"

import LayerOne from "./LayerOne/LayerOne"
import LayerTwo from "./LayerTwo/LayerTwo" 
import LayerThree from "./LayerThree/LayerThree"
import LayerFour from "./LayerFour/LayerFour"

const readingMessage = '*The ACER learning progression for reading comprehension currently begins at the point at which learners can read independently with sufficient fluency to allow comprehension of short, simple texts. Other reading skills that develop prior to this point are described in levels 1-4 of the learning progression, which will be added shortly.'

function ContentView(props){
    const copyrightMessage = props.copyrightMessage
    const levelInView = props.levelInView
    const lvlHeightRefArray = props.lvlHeightRefArray
    const strandHeightRefArray = props.strandHeightRefArray
    const illHeightRefArray = props.illHeightRefArray
    const contentViewWidth = props.contentViewWidth
    const NAV_BAR_HEIGHT = props.NAV_BAR_HEIGHT
    const renderNavigationController = props.renderNavigationController
    const lpeRenderNumber = props.lpeRenderNumber
    const hideShadowScroll = props.hideShadowScroll
    const renderLayerNumber = props.renderLayerNumber
    const NUMBER_OF_STRANDS = props.NUMBER_OF_STRANDS
    const NUMBER_OF_LEVELS = props.NUMBER_OF_LEVELS
    const windowHeight = props.windowHeight
    const windowWidth = props.windowWidth
    const levelCardsHeightToSetNumericalScaleRef = props.levelCardsHeightToSetNumericalScaleRef
    const strandHeightToSetNumericalScaleRef = props.strandHeightToSetNumericalScaleRef
    const setItemHeightToNumericalScaleHeightRef = props.setItemHeightToNumericalScaleHeightRef
    const numericalScaleHeight = props.numericalScaleHeight
    const scrollY = props.scrollY
    const LayerOneData = props.LayerOneData
    const LayerTwoData = props.LayerTwoData
    const LayerThreeData = props.LayerThreeData
    const LayerFourData = props.LayerFourData

    const setLayerFourItemModalHTML = props.setLayerFourItemModalHTML
    const setShowLayerFourItemModal = props.setShowLayerFourItemModal

    // TODO: Pull these constants out with useContext hook
    const strandStatementMargin = 0.013*contentViewWidth
    const levelTitleAndUnderlineMargin = 0.012*contentViewWidth
    const levelOfStrandsWidth = contentViewWidth/(NUMBER_OF_STRANDS+1)
    
    const totalStrandViewWidth = 0.9*contentViewWidth
    const levelIndicatorWidthArray = [null, null, null, 0.070*contentViewWidth, 0.05*contentViewWidth, 0.061*contentViewWidth, 0.05*contentViewWidth]
    const strandLabelWidth = contentViewWidth/(NUMBER_OF_STRANDS+1)

    const levelDividerMarginScaleFactor = [null, null, 0.0630, .0300, .014, .010, .010]
    const rightDividerMarginScaleFactor = [null, null, 0.0650, .0320, .019, .012, .012]
    const levelDividerMarginLeft = levelDividerMarginScaleFactor[NUMBER_OF_STRANDS]*contentViewWidth
    const levelDividerMarginRight = rightDividerMarginScaleFactor[NUMBER_OF_STRANDS]*contentViewWidth

   

    return (
        <div style={{width: `${contentViewWidth}px`}}> 
            <RequisiteSpacing NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}/>
            {(renderLayerNumber === 3 || renderLayerNumber === 4) && 
                <LayerThreeSpacing 
                    contentViewWidth={contentViewWidth}
                    NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}/>}            
            <div >
                <div>
                    {renderLayerNumber === 1 &&
                        <LayerOne
                            copyrightMessage={copyrightMessage}
                            scrollY={scrollY}
                            LayerOneData={LayerOneData}
                            contentViewWidth={contentViewWidth}
                            windowHeight={windowHeight}
                            windowWidth={windowWidth}
                            disableTickMarksAndArrowHead={true}
                            renderNavigationController={renderNavigationController}/>}
                    {renderLayerNumber === 2 &&
                        <LayerTwo 
                            copyrightMessage={copyrightMessage}
                            levelInView={levelInView}
                            readingMessage={readingMessage}
                            lvlHeightRefArray={lvlHeightRefArray}
                            levelCardsHeightToSetNumericalScaleRef={levelCardsHeightToSetNumericalScaleRef}
                            numericalScaleHeight={numericalScaleHeight}
                            contentViewWidth={contentViewWidth-40}
                            hideShadowScroll={hideShadowScroll}
                            NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                            NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                            windowHeight={windowHeight}
                            windowWidth={windowWidth}
                            LayerTwoData={LayerTwoData}
                            lpeRenderNumber={lpeRenderNumber}
                            scrollY={scrollY}/>}
                    {renderLayerNumber === 3 &&
                        <LayerThree
                            copyrightMessage={copyrightMessage}
                            lpeRenderNumber={lpeRenderNumber}
                            levelInView={levelInView}
                            readingMessage={readingMessage}
                            strandHeightRefArray={strandHeightRefArray}
                            strandHeightToSetNumericalScaleRef={strandHeightToSetNumericalScaleRef}
                            numericalScaleHeight={numericalScaleHeight} 
                            contentViewWidth={contentViewWidth-30}
                            NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                            windowHeight={windowHeight}
                            windowWidth={windowWidth}
                            LayerThreeData={LayerThreeData}
                            NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                            NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                            strandStatementMargin={strandStatementMargin}
                            levelTitleAndUnderlineMargin={levelTitleAndUnderlineMargin}
                            levelOfStrandsWidth={levelOfStrandsWidth}
                            levelIndicatorWidth={levelIndicatorWidthArray[NUMBER_OF_STRANDS]}
                            totalStrandViewWidth={totalStrandViewWidth}
                            strandLabelWidth={strandLabelWidth}
                            levelDividerMarginLeft={levelDividerMarginLeft}
                            levelDividerMarginRight={levelDividerMarginRight}
                            scrollY={scrollY}/>}
                    {renderLayerNumber === 4 &&
                        <LayerFour 
                            setShowLayerFourItemModal={setShowLayerFourItemModal}
                            setLayerFourItemModalHTML={setLayerFourItemModalHTML}
                            copyrightMessage={copyrightMessage}
                            lpeRenderNumber={lpeRenderNumber}
                            levelInView={levelInView}
                            readingMessage={readingMessage}
                            illHeightRefArray={illHeightRefArray}
                            setItemHeightToNumericalScaleHeightRef={setItemHeightToNumericalScaleHeightRef}
                            numericalScaleHeight={numericalScaleHeight} 
                            contentViewWidth={contentViewWidth-30}
                            NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                            windowHeight={windowHeight}
                            windowWidth={windowWidth}
                            LayerThreeData={LayerThreeData}
                            LayerFourData={LayerFourData}
                            NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                            NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                            strandStatementMargin={strandStatementMargin}
                            levelTitleAndUnderlineMargin={levelTitleAndUnderlineMargin}
                            levelOfStrandsWidth={levelOfStrandsWidth}
                            levelIndicatorWidth={levelIndicatorWidthArray[NUMBER_OF_STRANDS]}
                            totalStrandViewWidth={totalStrandViewWidth}
                            strandLabelWidth={strandLabelWidth}
                            levelDividerMarginLeft={levelDividerMarginLeft}
                            levelDividerMarginRight={levelDividerMarginRight}
                            scrollY={scrollY}/>}
                </div>
            </div>
        </div>
    )
}

export default ContentView