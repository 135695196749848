import StrandName from '../SharedContentView/StrandName'

function LevelOfStrands(props){

    const width = props.width
    const scrollY = props.scrollY
    const levelNumber = props.levelNumber
    const strandNumber = props.strandNumber
    const strandName = props.strandName
    const strandLabelWidth = props.strandLabelWidth
    const numberOfLevels = props.numberOfLevels

    let renderTitle 
    strandNumber === 0 ? renderTitle = true : renderTitle = false

    let showStrandName
    levelNumber === numberOfLevels ? showStrandName = true : showStrandName = false

    return (
        <>
            <div 
                className={'flex flex-column  b--dark-purple '} 
                style={{width: `${width}px`}}>
                {showStrandName && 
                    <StrandName 
                        scrollY={scrollY}
                        strandName={strandName} 
                        width={strandLabelWidth}/>}
                            {props.children}
            </div>
        </>
    )
}

export default LevelOfStrands