import TickMark from "./TickMark"
import ArrowUp from "./ArrowUp"

function NumericalScale(props){

    const NUMERICAL_SCALE_HEIGHT = props.numericalScaleHeight
    const TICK_MARK_WIDTH = 10
    const TICK_MARK_HEIGHT = 30
    const NAV_BAR_HEIGHT = props.NAV_BAR_HEIGHT

    const numberOfTickMarks = Math.round(NUMERICAL_SCALE_HEIGHT/TICK_MARK_HEIGHT)

    let tickMarks = []
    console.log(numberOfTickMarks)
    for (let i=0; i<numberOfTickMarks; i++){
        tickMarks.push(
            <TickMark 
                isMajor={true} 
                width={TICK_MARK_WIDTH} 
                height={TICK_MARK_HEIGHT}/>)
    }

    return (
        <div>
            <div className='bg-moon-gray' style={{position: 'fixed', height:'30px', width:'48px', zIndex:'10', top: NAV_BAR_HEIGHT}}></div>
            <ArrowUp NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}/>
            <div className='flex flex-row' style={{marginLeft:'19px'}}>
                <div className='ba bw2 b--dark-purple' style={{width:'.1vw'}}></div>
                <div className='flex flex-column'>
                    {tickMarks}
                </div>
            </div>
        </div>
            
    )
}

export default NumericalScale