function LayerOneLabel(props){

    const isSelected = props.isSelected
    const layerLabel = props.layerLabel


    return (
        <>
            <div className='flex justify-between' style={{zIndex:'1'}}>
                <div>
                    {!isSelected &&
                    <div className='pl1 pr1 pb1 bg-white mb1 br bw1 ba b--lpe-blue br2 shadow-4' style={{marginLeft: '0.1vw'}}>
                            <div className={'lpe-blue f6 mt1 bg-white'}>
                                {layerLabel}
                            </div>
                    </div>}
                    {isSelected &&
                    <div className='pl1 pr1 pb1 bg-lpe-blue mb1 br bw1 ba b--lpe-blue br2 shadow-4' style={{marginLeft: '0.1vw'}}>
                        <div className={'white b f6 mt1 bg-lpe-blue'}>
                                {layerLabel}
                            </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default LayerOneLabel