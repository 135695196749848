import App from "./App"
import ReactDOM from 'react-dom';
import React, { useState, useEffect, useCallback } from 'react'

function WindowResize(){

  const [windowDimensions, setWindowDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

    const [scrollY, setScrollY] = useState(0);
    const [keyPress, setKeyPress] = useState('')
    const [keyPressTrigger, setKeyPressTrigger] = useState(false)
  
    const handleKeyPress = useCallback(event => {
      setKeyPress(event.keyCode)
      setKeyPressTrigger(prevValue => !prevValue)
    }, [])
  
    const handleResize = useCallback(event => {
      setWindowDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
    }, [])

    const handleUserScroll = useCallback(event => {
      setScrollY(window.scrollY)
    }, []);
  
  useEffect(() => {
        window.addEventListener("scroll", handleUserScroll);
        window.addEventListener('resize', handleResize)
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("scroll", handleUserScroll);
            window.removeEventListener('resize', handleResize)
            window.removeEventListener("keydown", handleKeyPress);

        };
    }, [handleUserScroll, handleResize]);

  return (    
      <App 
        windowDimensions={windowDimensions} 
        scrollY={scrollY}
        keyPress={keyPress}
        keyPressTrigger={keyPressTrigger}/>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <div>
      <WindowResize />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);


