import isElementInViewPort from '../../helpers/percentageOfElementInViewPort';

import { useRef } from 'react'
function ItemContainer(props){

    const ItemContainerRef = useRef(null)    
    const itemHTML = props.itemHTML
    const margin = props.margin

    const setShowLayerFourItemModal = props.setShowLayerFourItemModal
    const setLayerFourItemModalHTML = props.setLayerFourItemModalHTML
    
    let renderThumbNail = isElementInViewPort(ItemContainerRef) ? true : false
    

    return (
        <div 
            onClick={e => e.stopPropagation()}
            ref={ItemContainerRef}    
            className='flex flex-column bg-white mr1 ba b--dark-purple bw1 br2' 
            style={{height: '30vh', marginTop: '1vh', marginBottom: '1vh'}}>
            <div
                onClick={ _ => {setLayerFourItemModalHTML(itemHTML); setShowLayerFourItemModal(true)}} 
                className='bg-white mt2 mb2 f6 pointer' 
                style={{
                    overflow: 'hidden',
                    marginLeft: `${margin}px`, 
                    marginRight: `${margin}px`}}>
                    {/* dangerouslySetInnerHTML is used here for performance improvements, parse(HTML) is used everywhere else */}
                    {renderThumbNail 
                        && <div dangerouslySetInnerHTML={{__html:itemHTML}}></div>}
            </div>
        </div>
    )
}

export default ItemContainer