import parse from 'html-react-parser';
import PageOutline from "./PageOutline"
import FadeInOutMessage from "../FadeInOutMessage"

function LayerOne(props){

    const copyrightMessage = props.copyrightMessage
    const contentViewWidth = props.contentViewWidth
    const LayerOneData = props.LayerOneData
    const scrollY = props.scrollY
    const renderNavigationController = props.renderNavigationController

    const scrollMaxY = document.documentElement.scrollHeight - document.documentElement.clientHeight

    let borderLeft
    renderNavigationController ? borderLeft = 'ba' : borderLeft = ''

    let boxShadow
    scrollY > 0.95*scrollMaxY ? boxShadow = 'none' : boxShadow = '0px -20px 20px 2px rgba(0,0,0,.3)'

    return (
        <div style={{width: `${contentViewWidth}px`}}>
            <div className='flex justify-between'>
                <div className={'bw2 b--dark-purple '+borderLeft} style={{width:'.1vw', marginLeft:'1.2vw'}}></div>
                    <div className='flex flex-column'>
                        <PageOutline contentViewWidth={contentViewWidth}>
                            {parse(LayerOneData[0])}
                        </PageOutline>
                        <FadeInOutMessage message={copyrightMessage} width={0.7*contentViewWidth} />
                        <div 
                            className='bg-moon-gray'
                            style={{
                                position: 'fixed', 
                                bottom: '-13vh', 
                                height: '13vh',
                                width: `${0.70*contentViewWidth}px`,
                                boxShadow: boxShadow
                            }}>
                        </div>
                    </div>
                <div></div>
                </div>
            </div>
    )
}

export default LayerOne