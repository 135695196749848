function LevelTitle(props){
    
    const levelNumber = props.levelNumber
    const contentViewWidth = props.contentViewWidth
    const levelInView = props.levelInView
    const lpeRenderNumber = props.lpeRenderNumber

    let levelOffset
    lpeRenderNumber === 1 ? levelOffset = 4 : levelOffset = 0

    let border
    levelInView === levelNumber ? border = 'b--lpe-teal' : border = 'b--moon-gray'

    return (
        <div className='bg-white b--dark-purple bw1'> 
            <div 
                className={`mt2 mb2 bb dark-purple b bw2 f3 ${border}`} 
                style={{
                    marginTop: '20px',
                    width: `${0.58*contentViewWidth}px`}}>
                        <div>Level {levelNumber+levelOffset}</div>
            </div>
        </div>
    )
}

export default LevelTitle