function PageOutline(props){
    const contentViewWidth = props.contentViewWidth
    return (
        <div style={{marginTop: '10vh', marginBottom: '10vh'}}>
            <div className='bg-white ba b--dark-purple br2 bw1' style={{width: `${0.7*contentViewWidth}px`}}>
                <div className='flex justify-center'>
                    <div className='bg-white' style={{width: `${0.55*contentViewWidth}px`}}>
                        <div className='flex flex-column'>
                            <div style={{marginTop: '10vh', marginBottom: '10vh'}}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageOutline