function RenderReading(props){
    const setLpeRenderNumber = props.setLpeRenderNumber

    setLpeRenderNumber(1)

    return (
        <></>
    )
}

export default RenderReading