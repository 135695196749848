function LayerTwoParentContainer(props){

    const L2_LEVEL_WIDTH = props.L2_LEVEL_WIDTH - 1
    const L2_LEVEL_HEIGHT = props.L2_LEVEL_HEIGHT - 0.3
    const layerNumber = props.layerNumber
    const levelNumber = props.levelNumber
    const enableTopBorder = props.enableTopBorder
    const handleNavigationControllerClick = props.handleNavigationControllerClick
    const levelInView = props.levelInView
    const renderLayerNumber= props.renderLayerNumber

    let ifBorderTopNeeded
    enableTopBorder ? ifBorderTopNeeded = 'bt ' : ifBorderTopNeeded = ''

    let borderWidth
    layerNumber === 2 ? borderWidth = ' bw2' : borderWidth = ' bw1'
    
    let background

    // Logic for layer 2 background, a bit clunky, but hard to do otherwise. Comment out the second if statement to see why
    levelNumber === levelInView && layerNumber === renderLayerNumber && renderLayerNumber === 2 ? background = ' bg-lpe-teal ' : background = ''

    if (levelNumber === 1 && levelNumber === levelInView && layerNumber === renderLayerNumber && renderLayerNumber === 2)
        background = ' bg-lpe-teal '
    //End logic for layer 2
    
    // Logic for layer 3 and 4 background
    if (levelNumber === levelInView && layerNumber === renderLayerNumber && (renderLayerNumber === 3 || renderLayerNumber === 4))
        background = ' bg-lpe-teal'

    return (
            <div 
                className={'b--dark-purple bw1 '+ifBorderTopNeeded+borderWidth+background} 
                style={{width:`${L2_LEVEL_WIDTH-3}px`, height:`${L2_LEVEL_HEIGHT}px`}}
                name={`Layer-${layerNumber}-Level-${levelNumber}-Strand-none`}
                onClick={handleNavigationControllerClick}>
                {props.children}
            </div>
    )
}

export default LayerTwoParentContainer