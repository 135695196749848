import React, { useRef, useState, useEffect } from 'react'
import './tachyons.css'
import './index.css';
import NavigationController from './NavigationController/NavigationController';
import NavigationBar from './NavigationBar/NavigationBar';
import ContentView from './ContentView/ContentView';
import mapClickEventToContentViewAction from './helpers/mapClickEventToContentViewAction';
import About from './shared/About';

import AboutData from './Data/GeneralInformation/AboutData.json'
import InfoDataGeneral from './Data/GeneralInformation/InfoDataGeneral.json'
import LayerOneDataGeneral from './Data/GeneralInformation/LayerOneData.json'
import LayerTwoDataGeneral from './Data/GeneralInformation/LayerTwoData.json'
import LayerThreeDataGeneral from './Data/GeneralInformation/LayerThreeData.json'
import LayerFourDataGeneral from './Data/GeneralInformation/LayerFourData.json'

import InfoDataSpecificMaths from './Data/Mathematics/InfoDataSpecific.json'
import LayerOneDataMaths from './Data/Mathematics/LayerOneData.json'
import LayerTwoDataMaths from './Data/Mathematics/LayerTwoData.json'
import LayerThreeDataMaths from './Data/Mathematics/LayerThreeData.json'
import InfoDataSpecificReading from './Data/Reading/InfoDataSpecific.json'
import LayerOneDataReading from './Data/Reading/LayerOneData.json'
import LayerTwoDataReading from './Data/Reading/LayerTwoData.json'
import LayerThreeDataReading from './Data/Reading/LayerThreeData.json'
import LayerFourDataReading from './Data/Reading/LayerFourData.json'

import determineLevelInView from './helpers/determineLevelInView';
import scrollToLevel from './helpers/scrollToLevel';

import RenderReading from './helpers/RenderReading';
import RenderMathematics from './helpers/RenderMathematics';
import RenderAbout from './helpers/RenderAbout'

import Modal from './shared/Modal'

import { HashRouter, Routes, Route } from "react-router-dom"
import LayerFourDataMaths from './Data/Mathematics/LayerFourData.json'

// "homepage": "https://standardSetting.github.io/learningProgressionExplorerV4",

function App(props) {
  const copyrightMessage = `© ${new Date().getFullYear()} Australian Council for Educational Research`


  /* THIS PART WILL BE MUCH CLEANER WITH A BACKEND TO CALL */
  const LPE_DATA = [
    [InfoDataGeneral, LayerOneDataGeneral, LayerTwoDataGeneral, LayerThreeDataGeneral, LayerFourDataGeneral],
    [InfoDataSpecificReading, LayerOneDataReading, LayerTwoDataReading, LayerThreeDataReading, LayerFourDataReading],
    [InfoDataSpecificMaths, LayerOneDataMaths, LayerTwoDataMaths, LayerThreeDataMaths, LayerFourDataMaths]
  ]

  const [lpeRenderNumber, setLpeRenderNumber] = useState(2)

  const InfoDataSpecific = LPE_DATA[lpeRenderNumber][0]
  const LayerOneData = LPE_DATA[lpeRenderNumber][1]
  const LayerTwoData = LPE_DATA[lpeRenderNumber][2]
  const LayerThreeData = LPE_DATA[lpeRenderNumber][3]
  const LayerFourData = LPE_DATA[lpeRenderNumber][4]
  /* END THIS PART WILL BE MUCH CLEANER WITH A BACKEND TO CALL */

  const contentViewHeightRef = useRef(null)
  const levelCardsHeightToSetNumericalScaleRef = useRef(null)
  const strandHeightToSetNumericalScaleRef = useRef(null)
  const setItemHeightToNumericalScaleHeightRef = useRef(null)
  const refArray = [null, null, levelCardsHeightToSetNumericalScaleRef, strandHeightToSetNumericalScaleRef, setItemHeightToNumericalScaleHeightRef]

  /* You would think there was a better way to do this, but I haven't found it yet. Will keep looking. It is possible to put useRef in a loop to create these, but that apparently violates the 'rules of hooks' and can lead to issues */
  const [lvl1Ref, lvl2Ref, lvl3Ref, lvl4Ref, lvl5Ref, lvl6Ref, lvl7Ref, lvl8Ref, lvl9Ref, lvl10Ref, lvl11Ref, lvl12Ref, lvl13Ref, lvl14Ref, lvl15Ref, lvl16Ref, lvl17Ref, lvl18Ref, lvl19Ref, lvl20Ref] = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const lvlHeightRefArray = [lvl1Ref, lvl2Ref, lvl3Ref, lvl4Ref, lvl5Ref, lvl6Ref, lvl7Ref, lvl8Ref, lvl9Ref, lvl10Ref, lvl11Ref, lvl12Ref, lvl13Ref, lvl14Ref, lvl15Ref, lvl16Ref, lvl17Ref, lvl18Ref, lvl19Ref, lvl20Ref] 
  const [strand1Ref, strand2Ref, strand3Ref, strand4Ref, strand5Ref, strand6Ref, strand7Ref, strand8Ref, strand9Ref, strand10Ref, strand11Ref, strand12Ref, strand13Ref, strand14Ref, strand15Ref, strand16Ref, strand17Ref, strand18Ref, strand19Ref, strand20Ref] = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const strandHeightRefArray = [strand1Ref, strand2Ref, strand3Ref, strand4Ref, strand5Ref, strand6Ref, strand7Ref, strand8Ref, strand9Ref, strand10Ref, strand11Ref, strand12Ref, strand13Ref, strand14Ref, strand15Ref, strand16Ref, strand17Ref, strand18Ref, strand19Ref, strand20Ref] 
  const [ill1Ref, ill2Ref, ill3Ref, ill4Ref, ill5Ref, ill6Ref, ill7Ref, ill8Ref, ill9Ref, ill10Ref, ill11Ref, ill12Ref, ill13Ref, ill14Ref, ill15Ref, ill16Ref, ill17Ref, ill18Ref, ill19Ref, ill20Ref] = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const illHeightRefArray = [ill1Ref, ill2Ref, ill3Ref, ill4Ref, ill5Ref, ill6Ref, ill7Ref, ill8Ref, ill9Ref, ill10Ref, ill11Ref, ill12Ref, ill13Ref, ill14Ref, ill15Ref, ill16Ref, ill17Ref, ill18Ref, ill19Ref, ill20Ref]
  /* END find better way to do this */

  const [renderNavigationController, setRenderNavigationController] = useState(true)
  const [navigationControllerAreaWidth, setNavigationControllerAreaWidth] = useState(350)
  const [numericalScaleHeight, setNumericalScaleHeight] = useState(0)
  const [levelInView, setLevelInView] = useState(1)

  const [layerFourItemModalHTML, setLayerFourItemModalHTML] = useState('')
  const [showLayerFourItemModal, setShowLayerFourItemModal] = useState(false)

  const windowDimensions = props.windowDimensions
  const windowHeight = windowDimensions.height
  const windowWidth = windowDimensions.width
  const scrollY = props.scrollY
  const keyPress = props.keyPress
  const keyPressTrigger = props.keyPressTrigger


  // Adjust these factors for sizing
  // Refactor with useContext
  
  // TODO Refactor constants with useContext hook to address prop drilling
  // adjustable dimensions
  const NUMBER_OF_STRANDS = LayerThreeData.strandNames.length
  const RIGHT_OFFSET_SCALE_FACTOR_LOOK_UP_ARRAY = [null, null, null, 1.5, 2.5, 3.8, 5]
  const WIDE_SCREEN_VIEW_WIDTH = [null, null, null, 1300, null, 1780, null]
  const RIGHT_OFFSET_SCALE_FACTOR = RIGHT_OFFSET_SCALE_FACTOR_LOOK_UP_ARRAY[NUMBER_OF_STRANDS]
  const NUMBER_OF_LEVELS = LayerTwoData.length
  const L4_ELEMENT_WIDTH = 5
  const L4_ELEMENT_HEIGHT = 5.2
  const L4_ELEMENT_WIDTH_SPACING_FACTOR = 6
  const L4_ELEMENT_HEIGHT_SPACING_FACTOR = 2.69
  const NAV_BAR_HEIGHT = '13vh'
  const GOLDEN_RATIO = 1.61
  
  // calculated dimensions
  const L3_ELEMENT_WIDTH = L4_ELEMENT_WIDTH_SPACING_FACTOR*L4_ELEMENT_WIDTH
  const L3_ELEMENT_HEIGHT = L4_ELEMENT_HEIGHT_SPACING_FACTOR*L4_ELEMENT_HEIGHT
  const TOP_OFFSET_FACTOR = L3_ELEMENT_HEIGHT*2
  const RIGHT_OFFSET_FACTOR = L3_ELEMENT_WIDTH*RIGHT_OFFSET_SCALE_FACTOR
  const L2_LEVEL_WIDTH = NUMBER_OF_STRANDS*L3_ELEMENT_WIDTH
  const L2_LEVEL_HEIGHT = L3_ELEMENT_HEIGHT
  const L1_WIDTH = L2_LEVEL_WIDTH
  const L1_HEIGHT = NUMBER_OF_LEVELS*L2_LEVEL_HEIGHT
  const NAVIGATION_CONTROLLER_CONTAINER_HEIGHT = GOLDEN_RATIO*L1_HEIGHT
  const NAVIGATION_CONTROLLER_MARGIN_LEFT = L3_ELEMENT_WIDTH

  const [contentViewWidth, setContentViewWidth] = useState(windowWidth - navigationControllerAreaWidth - 20)
  // End Adjust these factors for sizing
    
  // 0 is prefetch, 1 is Reading, 2 is Maths.
  // TODO make state 'Prefetch', 'Reading' and 'Maths'
  const [renderLayerNumber, setRenderLayerNumber] = useState(1)

  // handle window resizes
  useEffect(()=>{
    let navigationControllerAreaWidthTemp 
      if (windowWidth > WIDE_SCREEN_VIEW_WIDTH[NUMBER_OF_STRANDS]) {
        navigationControllerAreaWidthTemp = 350
        setRenderNavigationController(true)
      }  else {
        navigationControllerAreaWidthTemp = 5
        setRenderNavigationController(false)
      }

      setContentViewWidth(windowWidth - navigationControllerAreaWidthTemp - 20)
      setNavigationControllerAreaWidth(navigationControllerAreaWidthTemp)

      setNumericalScaleHeight(1280)
      setTimeout(()=>{
        if (refArray[renderLayerNumber] !== null)
          setNumericalScaleHeight(refArray[renderLayerNumber].current.clientHeight)
      })

      if (renderLayerNumber > 1){
        let options = {  block: "center" }
        scrollToLevel(
          levelInView,
          options,
          lvlHeightRefArray,
          strandHeightRefArray,
          illHeightRefArray)
      }

  }, [windowHeight, windowWidth, lpeRenderNumber])

  // handle change in learning progression render
  useEffect(()=>{
    let navigationControllerAreaWidthTemp 
      if (windowWidth > WIDE_SCREEN_VIEW_WIDTH[NUMBER_OF_STRANDS]) {
        navigationControllerAreaWidthTemp = 350
        setRenderNavigationController(true)
      }  else {
        navigationControllerAreaWidthTemp = 5
        setRenderNavigationController(false)
      }

      setContentViewWidth(windowWidth - navigationControllerAreaWidthTemp - 20)
      setNavigationControllerAreaWidth(navigationControllerAreaWidthTemp)

      setNumericalScaleHeight(1280)
      setTimeout(()=>{
        setRenderLayerNumber(1)
        window.scrollTo(0,0)
      }, 0)

  }, [lpeRenderNumber])

  // handle navigation clicks
  function handleNavigationControllerClick(event){
    mapClickEventToContentViewAction(
        event.target.attributes.name.nodeValue.split('-'),
        renderLayerNumber,
        levelInView,
        setRenderLayerNumber,
        setNumericalScaleHeight,
        refArray,
        lvlHeightRefArray,
        strandHeightRefArray,
        illHeightRefArray
      )
  }

  // handle keypresses
  useEffect(()=>{
    let layer = renderLayerNumber

    if (keyPress === 37 && layer > 1) layer--
    if (keyPress === 39 && layer < 4) layer++
    if (keyPress === 27) setShowLayerFourItemModal(false)

    mapClickEventToContentViewAction(
      [null, layer, null, 'none', null, 'none'],
      renderLayerNumber,
      levelInView,
      setRenderLayerNumber,
      setNumericalScaleHeight,
      refArray,
      lvlHeightRefArray,
      strandHeightRefArray,
      illHeightRefArray
    )
  }, [keyPressTrigger])

  // handle scrolling
  useEffect(()=>{
      let level = determineLevelInView(
          lvlHeightRefArray, 
          strandHeightRefArray, 
          illHeightRefArray)
      setLevelInView(level)
  },[scrollY])

  // About page page number counter
  const [pageNumber, setPageNumber] = useState(0) 

  return (
    <div onClick={_ => setShowLayerFourItemModal(false)}>
    {showLayerFourItemModal &&
      <div onClick={e => e.stopPropagation}>
          <Modal clickOk={ _ => setShowLayerFourItemModal(false)}>
              {/* dangerouslySetInnerHTML is used here for performance improvements, parse(HTML) is used everywhere else */}
                  <div dangerouslySetInnerHTML={{__html:layerFourItemModalHTML}}></div>
          </Modal>
      </div>}
    <HashRouter>
      <Routes>
        {/* This is clunky how it is copied and pasted for each LPE. But two is not problematic yet
        TODO: create a loop to handle N LPs */}
        <Route 
          path="/" 
          element={
            <>
            <RenderAbout 
              setLpeRenderNumber={setLpeRenderNumber} />
            <About
              copyrightMessage={copyrightMessage}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              modalTitle="About ACER's Learning Progressions" 
              aboutData={AboutData}
              InfoData={InfoDataGeneral} 
              renderLayerNumber={renderLayerNumber}
              NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
              NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
              L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
              L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
              L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH}
              L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
              TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}
              RIGHT_OFFSET_FACTOR={RIGHT_OFFSET_FACTOR}
              L2_LEVEL_WIDTH={L2_LEVEL_WIDTH}
              L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
              L1_WIDTH={L1_WIDTH}
              L1_HEIGHT={L1_HEIGHT}
              NAVIGATION_CONTROL_AREA_WIDTH={navigationControllerAreaWidth}
              NAVIGATION_CONTROLLER_MARGIN_LEFT={NAVIGATION_CONTROLLER_MARGIN_LEFT}
              NAVIGATION_CONTROLLER_CONTAINER_HEIGHT={NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}/>
              </>} 
          />
        <Route 
          path="/Reading" 
          element={<div className='bg-moon-gray'>
          <RenderReading 
            setLpeRenderNumber={setLpeRenderNumber} />
          <NavigationBar
            lpeRenderNumber={lpeRenderNumber} 
            levelInView={levelInView}
            renderNavigationController={renderNavigationController}
            NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
            InfoData={InfoDataSpecific} 
            renderLayerNumber={renderLayerNumber}
            handleNavigationControllerClick={handleNavigationControllerClick}
            NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
            NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
            L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
            L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
            L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH}
            L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
            TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}
            RIGHT_OFFSET_FACTOR={RIGHT_OFFSET_FACTOR}
            L2_LEVEL_WIDTH={L2_LEVEL_WIDTH}
            L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
            L1_WIDTH={L1_WIDTH}
            L1_HEIGHT={L1_HEIGHT}
            NAVIGATION_CONTROL_AREA_WIDTH={navigationControllerAreaWidth}
            NAVIGATION_CONTROLLER_MARGIN_LEFT={NAVIGATION_CONTROLLER_MARGIN_LEFT}
            NAVIGATION_CONTROLLER_CONTAINER_HEIGHT={NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}/>
            {renderNavigationController &&
              <div 
                className='flex flex-column' 
                style={{zIndex:'20', position: 'fixed', width: `${navigationControllerAreaWidth}px`, top: '12vh'}}>
                  <NavigationController
                    levelInView={levelInView}
                    InfoData={InfoDataSpecific} 
                    renderLayerNumber={renderLayerNumber}
                    handleNavigationControllerClick={handleNavigationControllerClick}
                    NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                    NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                    L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
                    L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
                    L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH}
                    L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
                    TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}
                    RIGHT_OFFSET_FACTOR={RIGHT_OFFSET_FACTOR}
                    L2_LEVEL_WIDTH={L2_LEVEL_WIDTH}
                    L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
                    L1_WIDTH={L1_WIDTH}
                    L1_HEIGHT={L1_HEIGHT}
                    NAVIGATION_CONTROL_AREA_WIDTH={navigationControllerAreaWidth}
                    NAVIGATION_CONTROLLER_MARGIN_LEFT={NAVIGATION_CONTROLLER_MARGIN_LEFT}
                    NAVIGATION_CONTROLLER_CONTAINER_HEIGHT={NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}/>
              </div>}
              <div className='' style={{marginLeft: `${navigationControllerAreaWidth}px`}}>
                <ContentView
                  setLayerFourItemModalHTML={setLayerFourItemModalHTML}
                  setShowLayerFourItemModal={setShowLayerFourItemModal}
                  copyrightMessage={copyrightMessage}
                  levelInView={levelInView}
                  lvlHeightRefArray={lvlHeightRefArray}
                  strandHeightRefArray={strandHeightRefArray}
                  illHeightRefArray={illHeightRefArray}
                  lpeRenderNumber={lpeRenderNumber}
                  levelCardsHeightToSetNumericalScaleRef={levelCardsHeightToSetNumericalScaleRef}
                  strandHeightToSetNumericalScaleRef={strandHeightToSetNumericalScaleRef}
                  setItemHeightToNumericalScaleHeightRef={setItemHeightToNumericalScaleHeightRef}
                  numericalScaleHeight={numericalScaleHeight}
                  setNumericalScaleHeight={setNumericalScaleHeight}
                  NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                  NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                  renderLayerNumber={renderLayerNumber}
                  renderNavigationController={renderNavigationController}
                  contentViewHeightRef={contentViewHeightRef}
                  windowHeight={windowHeight}
                  windowWidth={windowWidth}
                  NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                  contentViewWidth={contentViewWidth}
                  scrollY={scrollY}
                  LayerOneData={LayerOneData}
                  LayerTwoData={LayerTwoData}
                  LayerThreeData={LayerThreeData}
                  LayerFourData={LayerFourData}/>
              </div>
        </div>} />
        <Route 
          path="/Mathematics" 
          element={<div className='bg-moon-gray'>
          <RenderMathematics 
            setLpeRenderNumber={setLpeRenderNumber} />
          <NavigationBar 
            levelInView={levelInView}
            renderNavigationController={renderNavigationController}
            NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
            InfoData={InfoDataSpecific} 
            renderLayerNumber={renderLayerNumber}
            handleNavigationControllerClick={handleNavigationControllerClick}
            NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
            NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
            L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
            L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
            L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH}
            L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
            TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}
            RIGHT_OFFSET_FACTOR={RIGHT_OFFSET_FACTOR}
            L2_LEVEL_WIDTH={L2_LEVEL_WIDTH}
            L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
            L1_WIDTH={L1_WIDTH}
            L1_HEIGHT={L1_HEIGHT}
            NAVIGATION_CONTROL_AREA_WIDTH={navigationControllerAreaWidth}
            NAVIGATION_CONTROLLER_MARGIN_LEFT={NAVIGATION_CONTROLLER_MARGIN_LEFT}
            NAVIGATION_CONTROLLER_CONTAINER_HEIGHT={NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}/>
            {renderNavigationController &&
              <div 
                className='flex flex-column' 
                style={{zIndex:'20', position: 'fixed', width: `${navigationControllerAreaWidth}px`, top: '12vh'}}>
                  <NavigationController
                    levelInView={levelInView}
                    InfoData={InfoDataSpecific} 
                    renderLayerNumber={renderLayerNumber}
                    handleNavigationControllerClick={handleNavigationControllerClick}
                    NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                    NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                    L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
                    L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
                    L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH}
                    L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
                    TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}
                    RIGHT_OFFSET_FACTOR={RIGHT_OFFSET_FACTOR}
                    L2_LEVEL_WIDTH={L2_LEVEL_WIDTH}
                    L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
                    L1_WIDTH={L1_WIDTH}
                    L1_HEIGHT={L1_HEIGHT}
                    NAVIGATION_CONTROL_AREA_WIDTH={navigationControllerAreaWidth}
                    NAVIGATION_CONTROLLER_MARGIN_LEFT={NAVIGATION_CONTROLLER_MARGIN_LEFT}
                    NAVIGATION_CONTROLLER_CONTAINER_HEIGHT={NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}/>
              </div>}
              <div className='' style={{marginLeft: `${navigationControllerAreaWidth}px`}}>
                <ContentView
                  setLayerFourItemModalHTML={setLayerFourItemModalHTML}
                  setShowLayerFourItemModal={setShowLayerFourItemModal}
                  copyrightMessage={copyrightMessage}
                  levelInView={levelInView}
                  lvlHeightRefArray={lvlHeightRefArray}
                  strandHeightRefArray={strandHeightRefArray}
                  illHeightRefArray={illHeightRefArray}
                  lpeRenderNumber={lpeRenderNumber}
                  levelCardsHeightToSetNumericalScaleRef={levelCardsHeightToSetNumericalScaleRef}
                  strandHeightToSetNumericalScaleRef={strandHeightToSetNumericalScaleRef}
                  setItemHeightToNumericalScaleHeightRef={setItemHeightToNumericalScaleHeightRef}
                  numericalScaleHeight={numericalScaleHeight}
                  setNumericalScaleHeight={setNumericalScaleHeight}
                  NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                  NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                  renderLayerNumber={renderLayerNumber}
                  renderNavigationController={renderNavigationController}
                  contentViewHeightRef={contentViewHeightRef}
                  windowHeight={windowHeight}
                  windowWidth={windowWidth}
                  NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                  contentViewWidth={contentViewWidth}
                  scrollY={scrollY}
                  LayerOneData={LayerOneData}
                  LayerTwoData={LayerTwoData}
                  LayerThreeData={LayerThreeData}
                  LayerFourData={LayerFourData}/>
              </div>
        </div>} />
      </Routes>
    </HashRouter>
    </div>
  );
}

export default App