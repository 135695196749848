function StrandName(props){

    const strandName = props.strandName
    const width = props.width
    const scrollY = props.scrollY

    let boxShadow
    scrollY === 0 ? boxShadow = 'none' : boxShadow = "0px 20px 20px 0 rgba(0,0,0,.2)"

    return (
        <>
            <div style={{position: 'fixed', top:'14vh', width: width, marginRight: '20vw', zIndex: '10'}} >
                <div className='flex justify-center items-center bg-lpe-grey h3 bw2 ba b--lpe-blue mr1 dark-purple b' style={{boxShadow: boxShadow}}>
                    <div className='ml2 mr2 tc' style={{fontSize: 'clamp(.9rem, 0.85vw, 0.9vw)'}}>
                        {strandName}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StrandName