import NumericalScale from '../SharedContentView/NumericalScale/NumericalScale'
import StrandStatement from './StrandStatement'
import LevelOfStrands from './LevelOfStrands'
import Levels from '../SharedContentView/Levels'
import parse from 'html-react-parser';
import LevelIndicator from '../SharedContentView/LevelIndicator';
import PurpleLevelDivider from '../SharedContentView/PurpleLevelDivider';
import FadeInOutMessage from '../FadeInOutMessage';


function LayerThree(props){
    const NAV_BAR_HEIGHT = props.NAV_BAR_HEIGHT
    const levelInView = props.levelInView
    const strandHeightRefArray = props.strandHeightRefArray
    const strandHeightToSetNumericalScaleRef = props.strandHeightToSetNumericalScaleRef
    const numericalScaleHeight = props.numericalScaleHeight
    const lpeRenderNumber = props.lpeRenderNumber

    const LayerThreeData = props.LayerThreeData
    const strandText = LayerThreeData.strandText
    const strandNames = LayerThreeData.strandNames
    const scrollY = props.scrollY

    const strandStatementMargin = props.strandStatementMargin
    const levelTitleAndUnderlineMargin = props.levelTitleAndUnderlineMargin
    const levelOfStrandsWidth = props.levelOfStrandsWidth
    const totalStrandViewWidth = props.totalStrandViewWidth
    const levelIndicatorWidth = props.levelIndicatorWidth
    const strandLabelWidth = props.strandLabelWidth

    const levelDividerMarginLeft = props.levelDividerMarginLeft
    const levelDividerMarginRight = props.levelDividerMarginRight

    const readingMessage = props.readingMessage
    const copyrightMessage = props.copyrightMessage

    let levels = []
    let levelStrandTemp = []
    let levelOfStrandsTemp = []
    let levelNumber
    let numberOfStatements

    const numberOfLevels = strandText.length

    for (let i=strandText.length-1; i>=0; i--){
        levelNumber = i+1
        for (let j=0; j<strandText[i].length; j++){
        numberOfStatements = strandText[i][j].length
            for (var k=0; k<strandText[i][j].length; k++){
                levelStrandTemp.push(<StrandStatement 
                    statementNumber={k}
                    numberOfLevels={numberOfLevels}
                    levelNumber={levelNumber} 
                    strandName={strandNames[j]}
                    numberOfStatements={numberOfStatements}
                    margin={strandStatementMargin} 
                    text={parse(strandText[i][j][k])}/>)
            }

            levelOfStrandsTemp.push(
                <LevelOfStrands 
                    numberOfLevels={strandText.length}
                    margin={levelTitleAndUnderlineMargin} 
                    strandNumber={j}
                    scrollY={scrollY}
                    strandName={strandNames[j]}
                    strandLabelWidth={strandLabelWidth}
                    levelNumber={i+1} 
                    width={levelOfStrandsWidth}>
                        {levelStrandTemp}
                </LevelOfStrands>)
            levelStrandTemp = []
        }
        if (numberOfLevels === levelNumber){
            levels.push(<div style={{height:'8vh'}}></div>)
        }
        levels.push(
            <PurpleLevelDivider 
                levelDividerMarginLeft={levelDividerMarginLeft}
                levelDividerMarginRight={levelDividerMarginRight}
                levelNumber={levelNumber}
                lpeRenderNumber={lpeRenderNumber}/>)
        levels.push(<div style={{height:'1vh'}}></div>)    
        levels.push(
            <Levels 
                heightRef={strandHeightRefArray[i]}>
                    {levelOfStrandsTemp}
            </Levels>)
        levelOfStrandsTemp = []
    }

    // Hacky, but will do for now until a revised schema for learning progressions is devised.
    if (lpeRenderNumber === 1)
        levels.push(<FadeInOutMessage message={readingMessage} width={totalStrandViewWidth}/>)
        
    levels.push(<FadeInOutMessage message={copyrightMessage} width={totalStrandViewWidth} />)

    return (
        <>
            <div className='flex justify-between'>
                <NumericalScale 
                    NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                    numericalScaleHeight={numericalScaleHeight}/>
                <div className='flex flex-column' style={{width: `${totalStrandViewWidth}px`}} ref={strandHeightToSetNumericalScaleRef}>
                        {levels}
                </div>
                <div>
                    <LevelIndicator 
                        lpeRenderNumber={lpeRenderNumber}
                        levelInView={levelInView}
                        levelIndicatorWidth={levelIndicatorWidth}/>
                </div>
                <div>

                </div>
            </div>
        </>
    )
}

export default LayerThree