function PurpleLevelDivider(props){

    const levelDividerMarginLeft = props.levelDividerMarginLeft
    const levelDividerMarginRight = props.levelDividerMarginRight
    const levelNumber = props.levelNumber
    const lpeRenderNumber = props.lpeRenderNumber

    let levelOffset
    lpeRenderNumber === 1 ? levelOffset = 4 : levelOffset = 0

    return (
        <div 
            className='b--dark-purple bg-dark-purple ba bw1 mt4 br2' 
            style={{
                marginLeft: `${levelDividerMarginLeft}px`,
                marginRight: `${levelDividerMarginRight}px`,
            }}>
                <div className='ml2 white f4'>
                    {`Level ${levelNumber+levelOffset}`}
                </div>
        </div>
    )
}

export default PurpleLevelDivider