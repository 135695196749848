function LevelOutline(props){
    const contentViewWidth = props.contentViewWidth
    const lvlHeightRef = props.lvlHeightRef

    return (
        <div style={{width: `${contentViewWidth}px`}} ref={lvlHeightRef}>
            <div className='flex justify-center'>
                <div style={{marginTop: '1vh', marginBottom: '1vh'}}>
                    <div className='bg-white ba br2 b--dark-purple bw1' style={{width: `${0.72*contentViewWidth}px`}}>
                        <div className='flex justify-center'>
                            <div className='bg-white' style={{width: `${0.58*contentViewWidth}px`}}>
                                <div className='flex flex-column'>
                                    <div style={{marginTop: '4vh', marginBottom: '4vh'}}>
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LevelOutline