function Levels(props){
    const heightRef = props.heightRef

    return (
        <div className='flex justify-around' ref={heightRef}>
            {props.children}
        </div>
    )
}

export default Levels