function PurpleButton(props){
    const buttonText = props.buttonText
    const svgSrc = props.svgSrc
    return (
        <div className='bg-dark-purple no-underline ba white b--dark-purple pointer flex flex-row items-center grow' style={{width: '260px', height: '80px', marginBottom: '18px'}}>
            <div className='ml3 mr3'>
              <img src={svgSrc} width='80px' height='80px'/>
            </div>
            <div>
                {buttonText}
            </div>
        </div>
    )
}

export default PurpleButton;