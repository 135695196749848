function ReadingMessageTemp(props){

    const width = props.width
    const message = props.message
    
    const scrollMaxY = document.documentElement.scrollHeight - document.documentElement.clientHeight

    return (
        <>
            <div className='flex justify-center'>
                <div className='f6 mt1 mb1 ml3 mr1' style={{width: `${width}px`, minHeight: '30px'}}>
                {window.scrollY > 0.999 * scrollMaxY &&
                    <div className='fadeIn'>
                        {message}
                    </div>}
                {window.scrollY < 0.999 * scrollMaxY &&
                    <div className='o-0 fadeOut'>
                        {message}
                    </div>}
                </div>
            </div>
        </>
    )
}

export default ReadingMessageTemp