import LayerOneParentContainer from './LayerOneParentContainer'
import LayerTwoParentContainer from './LayerTwoParentContainer'
import LayerThreeParentContainer from './LayerThreeParentContainer'
import LayerFourParentContainer from './LayerFourParentContainer'

import LayerOneLabel from './LayerOneLabel'
import NavigationControllerMessage from './NavigationControllerMessage'

function NavigationController(props){
    const levelInView = props.levelInView
    const isForAboutModal = props.isForAboutModal
    const InfoData = props.InfoData
    const renderLayerNumber = props.renderLayerNumber
    const NUMBER_OF_STRANDS = props.NUMBER_OF_STRANDS
    const NUMBER_OF_LEVELS = props.NUMBER_OF_LEVELS
    const L4_ELEMENT_WIDTH = props.L4_ELEMENT_WIDTH
    const L4_ELEMENT_HEIGHT = props.L4_ELEMENT_HEIGHT
    const L3_ELEMENT_WIDTH = props.L3_ELEMENT_WIDTH
    const L3_ELEMENT_HEIGHT = props.L3_ELEMENT_HEIGHT    
    const TOP_OFFSET_FACTOR = props.TOP_OFFSET_FACTOR
    const RIGHT_OFFSET_FACTOR = props.RIGHT_OFFSET_FACTOR
    const L2_LEVEL_WIDTH = props.L2_LEVEL_WIDTH
    const L2_LEVEL_HEIGHT = props.L2_LEVEL_HEIGHT
    const L1_WIDTH = props.L1_WIDTH
    const L1_HEIGHT = props.L1_HEIGHT
    const NAVIGATION_CONTROL_AREA_WIDTH=props.NAVIGATION_CONTROL_AREA_WIDTH
    const NAVIGATION_CONTROLLER_MARGIN_LEFT = props.NAVIGATION_CONTROLLER_MARGIN_LEFT
    const NAVIGATION_CONTROLLER_CONTAINER_HEIGHT = props.NAVIGATION_CONTROLLER_CONTAINER_HEIGHT
    const handleNavigationControllerClick = props.handleNavigationControllerClick

    let AboutModalStyleMod

    const LayerLabels = ['Domain', 'Levels', 'Strands', 'Illustrations']

    if (isForAboutModal){
        AboutModalStyleMod = { 
            className: 'flex flex-row', 
            marginLeft: `-${100*NUMBER_OF_STRANDS-200}px`, 
            marginTop: '-10px'
        }
    } else {
        AboutModalStyleMod = { 
            className: '', 
            marginLeft: '', 
            marginTop: ''
        }
    }



    let layerTwoLevels = []
    for (let i=0; i<NUMBER_OF_LEVELS; i++){
        let enableTopBorder = i === 0 ? false : true
        layerTwoLevels.push(
            <LayerTwoParentContainer 
                levelInView={levelInView}
                L2_LEVEL_WIDTH={L2_LEVEL_WIDTH} 
                L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
                enableTopBorder={enableTopBorder}
                layerNumber={2}
                renderLayerNumber={renderLayerNumber}
                levelNumber={NUMBER_OF_LEVELS-i}
                handleNavigationControllerClick={handleNavigationControllerClick}>                  
            </LayerTwoParentContainer>
        )
    }

    let layerThreeLevels = []
    let layerThreeStrands = []
    var layerNumber = 3
    for (var j=0; j<NUMBER_OF_LEVELS; j++){
        let enableTopBorder = j === 0 ? false : true
        for (let k=0; k<NUMBER_OF_STRANDS; k++){
            let enableTopBorderL3 = j === 0 ? true : false
            let enableBottomBorderL3 = j === NUMBER_OF_LEVELS - 1 ? true : false
            layerThreeStrands.push(
                <LayerThreeParentContainer 
                    L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH} 
                    L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
                    layerNumber={layerNumber}
                    levelNumber={NUMBER_OF_LEVELS-j}
                    strandNumber={k+1}
                    enableTopBorder={enableTopBorderL3}
                    enableBottomBorder={enableBottomBorderL3}
                    handleNavigationControllerClick={handleNavigationControllerClick}>
                </LayerThreeParentContainer>)
        }
        layerThreeLevels.push(
            <LayerTwoParentContainer 
                levelInView={levelInView}
                L2_LEVEL_WIDTH={L2_LEVEL_WIDTH} 
                L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
                layerNumber={3}
                renderLayerNumber={renderLayerNumber}
                enableTopBorder={enableTopBorder}
                levelNumber={NUMBER_OF_LEVELS-j}
                handleNavigationControllerClick={handleNavigationControllerClick}>  
                    <div 
                        className='flex justify-around' style={{marginRight:'2.5px'}}
                        name={`Layer-${layerNumber}-Level-${NUMBER_OF_LEVELS-j}-Strand-none`}>
                        {layerThreeStrands}
                    </div>
            </LayerTwoParentContainer>)
        layerThreeStrands = []
    }

    let layerFourLevels = []
    let layerFourStrands = []
    layerNumber = 4
    for (let m=0; m<NUMBER_OF_LEVELS; m++){
        let enableTopBorder = m === 0 ? false : true
        for (let n=0; n<NUMBER_OF_STRANDS; n++){
            let enableTopBorderL3 = m === 0 ? true : false
            let enableBottomBorderL3 = m === NUMBER_OF_LEVELS - 1 ? true : false
            layerFourStrands.push(
                <LayerThreeParentContainer 
                    L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH} 
                    L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
                    layerNumber={layerNumber}
                    levelNumber={NUMBER_OF_LEVELS-m}
                    strandNumber={n+1}
                    enableTopBorder={enableTopBorderL3}
                    enableBottomBorder={enableBottomBorderL3}
                    handleNavigationControllerClick={handleNavigationControllerClick}>
                        <LayerFourParentContainer 
                            L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
                            L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
                            L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
                            layerNumber={layerNumber}
                            levelNumber={NUMBER_OF_LEVELS-m}
                            strandNumber={n+1}
                            handleNavigationControllerClick={handleNavigationControllerClick}
                            enableMarginBottom={enableTopBorderL3 || enableBottomBorderL3}/>
                </LayerThreeParentContainer>)
        }
        layerFourLevels.push(
            <LayerTwoParentContainer 
                levelInView={levelInView}
                L2_LEVEL_WIDTH={L2_LEVEL_WIDTH} 
                L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
                layerNumber={layerNumber}
                levelNumber={NUMBER_OF_LEVELS-m}
                renderLayerNumber={renderLayerNumber}
                strandNumber={'none'}
                handleNavigationControllerClick={handleNavigationControllerClick}
                enableTopBorder={enableTopBorder}>  
                    <div 
                        className='flex justify-around' 
                        style={{marginRight:'2.5px'}}
                        name={`Layer-${layerNumber}-Level-${NUMBER_OF_LEVELS-m}-Strand-none`}>
                        {layerFourStrands}
                    </div>
            </LayerTwoParentContainer>)
        layerFourStrands = []
    }

    let allLevelChildren = [null, layerTwoLevels, layerThreeLevels, layerFourLevels]
    let layerParents = []

    
    for (let p=0; p<allLevelChildren.length; p++){
        let isSelected = p + 1 === renderLayerNumber
        layerParents.push(
            <div 
                className='ma2' 
                style={{
                    position: 'relative', 
                    top: `${TOP_OFFSET_FACTOR*4-TOP_OFFSET_FACTOR*p}px`, 
                    right: `${RIGHT_OFFSET_FACTOR*p}px`, 
                    zIndex:`${2*allLevelChildren.length-p}`}}>
            <LayerOneLabel 
                isSelected={isSelected}
                layerLabel={LayerLabels[p]} />
            <LayerOneParentContainer 
                L1_WIDTH={L1_WIDTH} 
                L1_HEIGHT={L1_HEIGHT} 
                layerNumber={p+1}
                renderLayerNumber={renderLayerNumber}
                handleNavigationControllerClick={handleNavigationControllerClick}>
                    {allLevelChildren[p]}
            </LayerOneParentContainer>
        </div>
        )
    }


    return (
        <>
            <div className={AboutModalStyleMod.className}>
                <div className='' 
                    style={{height: `${NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}px`}}>
                    <div style={{marginLeft: `${NAVIGATION_CONTROLLER_MARGIN_LEFT}px`}}>
                        <div className='flex flex-row'>
                            {layerParents}
                        </div>
                    </div>
                </div>
                <div className='' style={{width: `${350}px`, marginLeft: AboutModalStyleMod.marginLeft, marginTop: AboutModalStyleMod.marginTop}}>
                    <div className='flex justify-center'>
                        <NavigationControllerMessage 
                            isForAboutModal={isForAboutModal}
                            InfoData={InfoData}
                            renderLayerNumber={renderLayerNumber}
                            NAVIGATION_CONTROL_AREA_WIDTH={NAVIGATION_CONTROL_AREA_WIDTH}
                            TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavigationController