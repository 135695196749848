function RenderMathematics(props){
    const setLpeRenderNumber = props.setLpeRenderNumber

    setLpeRenderNumber(2)

    return (
        <></>
    )
}

export default RenderMathematics