function scrollToLevel(
    level, 
    options,
    lvlHeightRefArray, 
    strandHeightRefArray, 
    illHeightRefArray) 
{
    let refArray = null
    if (lvlHeightRefArray[0].current !== null) refArray = lvlHeightRefArray
    if (strandHeightRefArray[0].current !== null) refArray = strandHeightRefArray
    if (illHeightRefArray[0].current !== null) refArray = illHeightRefArray
    if (refArray === null) return

    refArray[level-1].current.scrollIntoView(options)
    return

}

export default scrollToLevel