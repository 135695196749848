import LevelCard from './LevelCard'
import NumericalScale from '../SharedContentView/NumericalScale/NumericalScale'
import LevelOutline from './LevelOutline'
import FadeInOutMessage from '../FadeInOutMessage'

function LayerTwo(props){
    const levelInView = props.levelInView
    const lvlHeightRefArray = props.lvlHeightRefArray
    const readingMessage = props.readingMessage
    const copyrightMessage = props.copyrightMessage
    const lpeRenderNumber = props.lpeRenderNumber
    const LayerTwoData = props.LayerTwoData
    const scrollY = props.scrollY
    const NAV_BAR_HEIGHT = props.NAV_BAR_HEIGHT
    const NUMBER_OF_LEVELS = props.NUMBER_OF_LEVELS
    const hideShadowScroll = props.hideShadowScroll
    const contentViewWidth = props.contentViewWidth
    const numericalScaleHeight = props.numericalScaleHeight
    const levelCardsHeightToSetNumericalScaleRef = props.levelCardsHeightToSetNumericalScaleRef

    var levelCards = []
    
    let boxShadow
    scrollY === 0 ? boxShadow = 'none' : boxShadow = '0px 20px 20px 2px rgba(0,0,0,.2)'

    levelCards.push(
        <div className='flex justify-center'>
            <div 
                className='ba bg-moon-gray' 
                style={{
                        height: '13vh', 
                        top: '0vh',
                        position: 'fixed',
                        width: `${0.721*contentViewWidth}px`,
                        boxShadow: boxShadow
                    }}>
            </div>
        </div>
    )
    
    for (let i=NUMBER_OF_LEVELS; i>0; i--){
        levelCards.push(
        <LevelOutline 
            level={i}
            levelInView={levelInView}
            contentViewWidth={contentViewWidth}
            lvlHeightRef={lvlHeightRefArray[i-1]}>
                <LevelCard 
                    levelInView={levelInView}
                    lpeRenderNumber={lpeRenderNumber}
                    levelCardIndex={i} 
                    LayerTwoData={LayerTwoData}
                    contentViewWidth={contentViewWidth}/>
        </LevelOutline>)
    }
    
    // Hacky, but will do for now until a revised schema for learning progressions is devised.
    if (lpeRenderNumber === 1)
        levelCards.push(<FadeInOutMessage message={readingMessage} width={0.721*contentViewWidth}/>)
    
    levelCards.push(<FadeInOutMessage message={copyrightMessage} width={0.721*contentViewWidth} />)

    return (
        <div>
            <div className='flex flex-row'>
                <NumericalScale 
                    hideShadowScroll={hideShadowScroll}
                    NAV_BAR_HEIGHT={NAV_BAR_HEIGHT}
                    numericalScaleHeight={numericalScaleHeight}/>
                    <div ref={levelCardsHeightToSetNumericalScaleRef}>
                        {levelCards}
                    </div>
            </div>
        </div>
    )
}

export default LayerTwo