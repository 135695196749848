import ButtonContainer from "./ButtonContainer";
import ArrowRight from "./ArrowRight";

function RightButton(props){
    const text = props.text;
    return (
        <ButtonContainer>
            <span className="pr1 b">{text}</span>
            <ArrowRight />
        </ButtonContainer>
    )
}

export default RightButton