function Cross(props){

    const closeModal = props.closeModal
    
    return (
        <div className='ba br2 mb2 bw1 pointer b--dark-purple dark-purple mr1' onClick={closeModal}>
            <div className='ml1 mr1 b f4'>
                &times;
            </div>
        </div>
    )
}

export default Cross