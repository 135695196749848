function StrandStatement(props){
        
    const text = props.text
    const margin = props.margin

    let marginBottom
    
    return (
        <>
            <div 
                className='bg-white mt2 mb2 f6' 
                style={{
                    marginLeft: `${margin}px`, 
                    marginRight: `${margin}px`,
                    marginBottom: marginBottom}}>
                {text}
            </div>
        </>
    )
}

export default StrandStatement