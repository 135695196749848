import parse from 'html-react-parser';

function NavigationControllerMessage(props){
    const InfoData = props.InfoData
    const renderLayerNumber = props.renderLayerNumber
    const TOP_OFFSET_FACTOR = props.TOP_OFFSET_FACTOR

    return (
        <div className='bg-white br2 bw1 pa3 ba b--lpe-blue' 
            style={{
                width: `${350 - 30}px`,
                marginTop: `${TOP_OFFSET_FACTOR*3.5}px`}}>
            
            <div className='br2'>
                <div className='lpe-blue b bb b--black bw1 pb1'>
                    {InfoData[renderLayerNumber-1].LayerNutshellDescription}
                </div>
                <div className='f6 mt3'>
                    {parse(InfoData[renderLayerNumber-1].LayerElaboration)}
                </div>
            </div>
        </div>
    )
}

export default NavigationControllerMessage