import LevelCardElement from "./LevelCardElement"
import LevelTitle from "./LevelTitle"

function LevelCard(props){
    const lpeRenderNumber = props.lpeRenderNumber
    const LayerTwoData = props.LayerTwoData
    const levelCardIndex = props.levelCardIndex
    const contentViewWidth = props.contentViewWidth
    const levelInView = props.levelInView

    return (
        <div className='bg-white'>
                <div className='flex flex-row'>
                    <div className='flex flex-column'>
                        <div className='b--dark-purple' >
                            <LevelTitle 
                                lpeRenderNumber={lpeRenderNumber}
                                levelNumber={levelCardIndex} 
                                contentViewWidth={contentViewWidth}
                                levelInView={levelInView}/>
                            <div className='dark-purple mb3 mt3 f4'>
                                {LayerTwoData[levelCardIndex-1]['Nutshell statement']}
                            </div>
                            {lpeRenderNumber === 2 &&
                                <div className=''>
                                    <LevelCardElement title='Conceptual understanding' paragraph={LayerTwoData[levelCardIndex-1]['Conceptual understanding']}/>
                                    <LevelCardElement title='Procedural fluency' paragraph={LayerTwoData[levelCardIndex-1]['Procedural fluency']}/>
                                    <LevelCardElement title='Strategic competence' paragraph={LayerTwoData[levelCardIndex-1]['Strategic competence']}/>
                                    <LevelCardElement title='Adaptive reasoning' paragraph={LayerTwoData[levelCardIndex-1]['Adaptive reasoning']}/>
                                </div>}
                            {lpeRenderNumber === 1 &&
                                <div className=''>
                                    <LevelCardElement title={''} paragraph={LayerTwoData[levelCardIndex-1]['Description']}/>
                                </div>}
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div>
    )
}

export default LevelCard