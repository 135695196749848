import { Link } from "react-router-dom";
import parse from 'html-react-parser';

import LeftButton from './LeftButton';
import RightButton from './RightButton';

import NavigationController from '../NavigationController/NavigationController';
import PurpleButton from './PurpleButton';

import readingIcon from './readingIcon.svg'
import mathsIcon from './mathsIcon.svg'


function About(props) {

    const copyrightMessage = props.copyrightMessage
    const modalTitle = props.modalTitle
    const aboutData = props.aboutData
    const hideControllerMessage = props.hideControllerMessage
    const InfoData = props.InfoData
    const pageNumber = props.pageNumber
    const setPageNumber = props.setPageNumber
    const NUMBER_OF_STRANDS = props.NUMBER_OF_STRANDS
    const NUMBER_OF_LEVELS = props.NUMBER_OF_LEVELS
    const L4_ELEMENT_WIDTH = props.L4_ELEMENT_WIDTH
    const L4_ELEMENT_HEIGHT = props.L4_ELEMENT_HEIGHT
    const L3_ELEMENT_WIDTH = props.L3_ELEMENT_WIDTH
    const L3_ELEMENT_HEIGHT = props.L3_ELEMENT_HEIGHT    
    const TOP_OFFSET_FACTOR = props.TOP_OFFSET_FACTOR
    const RIGHT_OFFSET_FACTOR = props.RIGHT_OFFSET_FACTOR
    const L2_LEVEL_WIDTH = props.L2_LEVEL_WIDTH
    const L2_LEVEL_HEIGHT = props.L2_LEVEL_HEIGHT
    const L1_WIDTH = props.L1_WIDTH
    const L1_HEIGHT = props.L1_HEIGHT
    const NAVIGATION_CONTROL_AREA_WIDTH=props.NAVIGATION_CONTROL_AREA_WIDTH
    const NAVIGATION_CONTROLLER_MARGIN_LEFT = props.NAVIGATION_CONTROLLER_MARGIN_LEFT
    const NAVIGATION_CONTROLLER_CONTAINER_HEIGHT = props.NAVIGATION_CONTROLLER_CONTAINER_HEIGHT
    
    const MAX_PAGE_NUMBER = 5

    let marginTop
    pageNumber > 0 && pageNumber < MAX_PAGE_NUMBER ? marginTop = 'mt5' : marginTop = ''

    return (
        <>
        <div className='bg-dark-purple flex justify-center items-center' style={{height: '100vh', width: '100vw'}}>
            <div 
                className='ba b--dark-purple pa4 br2 bg-white shadow-4'
                style={{width: '750px'}}>            
                <p className='dark-purple b f4'>{modalTitle}</p>
                {(pageNumber === 0 || pageNumber === MAX_PAGE_NUMBER) &&
                    <div className='flex flex-row'>
                        <div className={`ml2 mr4 dark-gray mb4 ${marginTop}`}>
                            {parse(aboutData[pageNumber])}
                        </div>
                    </div>}
                        {pageNumber === MAX_PAGE_NUMBER &&
                        <div className="flex justify-between" style={{width: '685px', marginBottom: '38px'}}>
                            <div className="ml2">
                                <Link to="/Reading" style={{ textDecoration: 'none' }}>
                                    <PurpleButton svgSrc={readingIcon} buttonText='Explore the learning progression for reading' />
                                </Link>
                            </div>
                            <div className="mr4">
                                <Link to="/Mathematics" style={{ textDecoration: 'none' }}>
                                    <PurpleButton svgSrc={mathsIcon} buttonText='Explore the learning progression for mathematics' />
                                </Link>
                            </div>
                        </div>}
                {pageNumber > 0 && pageNumber < MAX_PAGE_NUMBER &&
                    <div className='flex justify-center' style={{marginTop: '-20px', height: '316px'}}>
                        <NavigationController
                            hideControllerMessage={hideControllerMessage}
                            InfoData={InfoData} 
                            renderLayerNumber={pageNumber}
                            handleNavigationControllerClick={()=>{}}
                            NUMBER_OF_STRANDS={NUMBER_OF_STRANDS}
                            NUMBER_OF_LEVELS={NUMBER_OF_LEVELS}
                            L4_ELEMENT_WIDTH={L4_ELEMENT_WIDTH}
                            L4_ELEMENT_HEIGHT={L4_ELEMENT_HEIGHT}
                            L3_ELEMENT_WIDTH={L3_ELEMENT_WIDTH}
                            L3_ELEMENT_HEIGHT={L3_ELEMENT_HEIGHT}
                            TOP_OFFSET_FACTOR={TOP_OFFSET_FACTOR}
                            RIGHT_OFFSET_FACTOR={RIGHT_OFFSET_FACTOR}
                            L2_LEVEL_WIDTH={L2_LEVEL_WIDTH}
                            L2_LEVEL_HEIGHT={L2_LEVEL_HEIGHT}
                            L1_WIDTH={L1_WIDTH}
                            L1_HEIGHT={L1_HEIGHT}
                            isForAboutModal={true}
                            NAVIGATION_CONTROL_AREA_WIDTH={NAVIGATION_CONTROL_AREA_WIDTH}
                            NAVIGATION_CONTROLLER_MARGIN_LEFT={NAVIGATION_CONTROLLER_MARGIN_LEFT}
                            NAVIGATION_CONTROLLER_CONTAINER_HEIGHT={NAVIGATION_CONTROLLER_CONTAINER_HEIGHT}/>
                    </div>}
                    <div className={''+marginTop}>
                        <div className='flex justify-between'>
                            { pageNumber === 0 && <div></div>}
                            {pageNumber !== 0 &&
                            <div className='pointer' onClick={ _ => setPageNumber(prevValue => prevValue - 1)}>
                                <LeftButton text='Previous'/>
                            </div>}
                            {pageNumber !== MAX_PAGE_NUMBER &&
                            <div className='pointer' onClick={ _ => setPageNumber(prevValue => prevValue + 1)}>
                                <RightButton text='Next'/>
                            </div>}
                            { pageNumber === MAX_PAGE_NUMBER && <div></div>}
                        </div>
                    </div>

            </div>
        </div>
        <div style={{
            position: 'fixed', 
            bottom: '5px', 
            left: '8px', 
            color: '#ccc', 
            fontSize: '12px', 
            width: '310px'
        }}>
            {copyrightMessage}
        </div>
        </>
    );
}

export default About;